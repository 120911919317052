import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Logo from '../images/rock-solid-borders-black-logo.svg';

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
  query PhoneQuery {
    site {
      siteMetadata {
        company {
            phone
        }
      }
    }
  }
`)  

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
    >
      <Navbar.Brand id="home">
        <img
          src={Logo}
          width="300px"
          height="auto"
          className="d-inline-block align-top"
          alt="Rock Solid Borders LLC Logo"
        />
      </Navbar.Brand>
  
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            href={`tel:${data.site.siteMetadata.company.phone}`}
          >
            Call {`(${data.site.siteMetadata.company.phone.slice(1, 4)}) ${data.site.siteMetadata.company.phone.slice(4, 7)}-${data.site.siteMetadata.company.phone.slice(7, 11)}`}
          </Nav.Link>
        </Nav>
  
        <Nav>
          {/* <Nav.Link href="#curbStyles">
            Curb Styles
          </Nav.Link>

          <Nav.Link href="#reviews">
            Reviews
          </Nav.Link> */}

          <Nav.Link href="#about">
            About
          </Nav.Link>
  
          <Nav.Link href="#ourCurbs">
            Our Curbs
          </Nav.Link>

          <Nav.Link href="#freeEstimate">
            Free Estimate
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header