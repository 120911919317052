import React from "react";
import styled from 'styled-components';

import config from '../globalVariables';

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-around;
    background-color: #222222;
    color: white;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }    
`;

const StyledLink = styled.a`
    padding-right: 20px;
    color: white;
`;

const LinkBlock = styled.div`
    align-self: center;
    margin-bottom: 5%;
`;

const AddressBlock = styled.address`
    text-align: center;
`;

const AddressTitle = styled.span`
    font-weight: bold;
`;

const PhoneLink = styled.a`
    color: ${config.baseColor};
`;

const Footer = ({ phoneNumber }) => (
    <StyledFooter>
        <AddressBlock>
            <AddressTitle>Contact Us:</AddressTitle> <br />
            Rock Solid Borders, LLC <br />
            Fairfield, IA 52556 <br />
            <PhoneLink
                href={`tel:${phoneNumber}`}
                alt="Phone number of Rock Solid Borders, LLC"
            >
                {`(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`}
            </PhoneLink><br />
        </AddressBlock>

        <LinkBlock>
            {/* <StyledLink href="#curbStyles" alt="">Curb Styles</StyledLink> */}
            {/* <StyledLink href="#reviews" alt="">Reviews</StyledLink> */}
            <StyledLink href="#about" alt="">About</StyledLink>
            <StyledLink href="#ourCurbs" alt="">Our Curbs</StyledLink>
            <StyledLink href="#freeEstimate" alt="">Free Estimate</StyledLink>
        </LinkBlock>
    </StyledFooter>
)

export default Footer