const config = {
    baseColor: '#DDB37A', //tan #DDB37A
    baseColorRGBA: 'rgba(221,179,122,.9)', //tan #DDB37A
    // baseColor: '#D1A74A', //gold #D1A74A
    // baseColorRGBA: 'rgba(221,179,122,.9)', //gold #D1A74A
    // baseColor: '#1C75BC', //lightblue #1C75BC
    // baseColorRGBA: 'rgba(28,117,188,.9)', //lightblue #1C75BC
    // baseColor: '#f8981d', //orange #f8981d
    // baseColorRGBA: 'rgba(248,152,52,.9)', //orange #f8981d
    // baseColor: '#0A3161', //old glory blue #0A3161
    // baseColorRGBA: 'rgba(10,49,97,.9)', //old glory blue #0A3161
    // baseColor: '#B31942', //old glory red #B31942
    // baseColorRGBA: 'rgba(179,25,66,.9)', //old glory red #B31942
};

export default config