import React from "react";

import styled from 'styled-components';

import Logo from '../images/rock-solid-borders-white-logo.svg';

const LogoBannerContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: #222222;
`;

const FooterImg = styled.img`
    max-width: 400px;
`;

const LogoBanner = () => (
    <LogoBannerContainer>
            <a
                href="#home"
                alt="Rock Solid Borders LLC logo home link"
            >
                <FooterImg src={Logo} alt="Rock Solid Borders LLC logo" />
            </a>
    </LogoBannerContainer>
)

export default LogoBanner