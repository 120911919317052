import React from "react";

import styled from 'styled-components';
import config from '../globalVariables';

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: ${config.baseColor};
    padding-top: 2%;
    padding-bottom: 2%;
`;

const DeveloperAttribution = () => (
    <FlexContainer>
        <div>
            Rock Solid Borders, LLC. © {new Date().getFullYear()} | All Rights Reserved
        </div>

        <div>
            Made in the USA by
            {` `}
            <a href="https://dallasboyer.com">All Web Development</a>
        </div>
    </FlexContainer>
)

export default DeveloperAttribution