import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import config from '../globalVariables';

const StyledRow = styled(Row)`
  background-color: #222222;
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
  color: white;
`;

const CallLink = styled.a`
    color: ${config.baseColor};
`;

const TopBar = () => {
    const data = useStaticQuery(graphql`
    query ContactNumber2Query {
      site {
        siteMetadata {
          company {
              phone
          }
        }
      }
    }
  `)

    return  (
        <StyledRow>
            <Col>
                One-of-a-kind Natural "Stone" Concrete Curbs.  Call Today <CallLink href={`tel:${data.site.siteMetadata.company.phone}`}>{`(${data.site.siteMetadata.company.phone.slice(1, 4)}) ${data.site.siteMetadata.company.phone.slice(4, 7)}-${data.site.siteMetadata.company.phone.slice(7, 11)}`}</CallLink>
            </Col>
        </StyledRow>
    )
}

export default TopBar