/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            company {
              phone
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        // {
        //   property: `og:title`,
        //   content: title,
        // },
        // {
        //   property: `og:description`,
        //   content: metaDescription,
        // },
        // {
        //   property: `og:type`,
        //   content: `website`,
        // },
      ].concat(meta)}
    >
      <link rel="canonical" href={`${site.siteMetadata.siteUrl}`} />
      <meta name="description" content={`Rock Solid Borders makes landscape curbing in Southeast Iowa.  Unlike other border edging, we create concrete border edging that doesn't rust, break, or move.  For the best Landscape Curbing, Call ${site.siteMetadata.company.phone.slice(1, 4)}) ${site.siteMetadata.company.phone.slice(4, 7)}-${site.siteMetadata.company.phone.slice(7, 11)}.`} />
      {/* geo tabs generated by https://www.geo-tag.de/generator/en.html */}
      {/* NOTE Used Iowa City as location */}
      <meta name="geo.region" content="US-IA" />
      <meta name="geo.placename" content="Iowa City" />
      <meta name="geo.position" content="41.661256;-91.529911" />
      <meta name="ICBM" content="41.661256, -91.529911" />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en-US`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
