/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./Footer";
import LogoBanner from "./LogoBanner";
import "./layout.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import DeveloperAttribution from "./DeveloperAttribution";
import TopBar from "./TopBar";

const NoPaddingContainer = styled(Container)`
  padding: 0;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          company {
            phone
          }          
        }
      }
    }
  `)

  return (
    <>
      <TopBar />
      
      <Header siteTitle={data.site.siteMetadata.title} />

      <NoPaddingContainer fluid>

        <main>{children}</main>

        <LogoBanner />

        <Footer phoneNumber={data.site.siteMetadata.company.phone} />

        <DeveloperAttribution />
        
      </NoPaddingContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout